<template>
  <Loader :value="promises.fetch" class="post-page" :key="post.id">
    <Blocks v-if="item.posts" :blocks="config" :custom-components="customComponents" />
  </Loader>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import CustomComponents from '@/components/Custom'

import Config from '../../config/posts'
import { Blocks } from '@kvass/pagebuilder'

export default {
  props: {
    slug: {
      type: String,
      default: 'post',
    },
  },
  computed: {
    ...mapState('Root', ['promises', 'item']),
    config() {
      const sources = this.post.customFieldsConfig?.map(schema => schema.source) || ['subpage']
      return Config[sources[0]].call(this)
    },
    post() {
      if (!this.item.posts) return {}
      return this.item.posts.find(p => p.slug === this.slug)
    },
    customComponents() {
      return CustomComponents
    },
  },
  methods: {
    ...mapActions('Root', ['fetch']),
  },
  created() {
    this.fetch()
  },

  metaInfo() {
    return {
      title: this.$path('title', this.post),
    }
  },

  components: {
    Blocks,
  },
}
</script>

<style lang="scss">
.post-page {
  .section-info {
    @include respond-below('phone') {
      padding-top: 1rem;
    }
    p:last-child {
      a[href='#lead'] {
        font-size: 1.2rem;
      }
    }
    @include respond-above('phone') {
      h1:first-child {
        text-align: center;
      }
      p:nth-child(1),
      p:nth-child(2) {
        text-align: center;
        margin-bottom: 2em;
      }
      p:last-child {
        text-align: center;
        margin-top: 2rem;
      }
    }
  }
}
</style>
