export default function() {
  const get = (path, source = 'Root.item.posts') => {
    const slug = this.$path('_props.slug', this)
    if (!slug) return

    let data = this.$path(source, this.$store.state) || []
    if (source.includes('posts')) {
      data = data.find(p => p.slug === slug)
      if (!data) return
    }

    return this.$path(path, data)
  }
  function transformContent(content, transform = ['h2', 'h1']) {
    if (!content) return
    return content.replace(transform[0], transform[1])
  }

  return [
    {
      component: 'Section',
      props: {
        width: 'full',
        id: 'section-hero',
        class: 'section-hero',
        style: 'padding:0;',
      },
      blocks: {
        default: [
          {
            component: 'Slider',
            data: get('media.cover'),
            props: {
              aspectRatio: '16/9',
              class: 'hero__slider',
              options: {
                'navigation-placement': 'bottom',
              },
            },
          },
        ],
      },
    },

    {
      component: 'Section',
      props: {
        width: 'medium',
        id: 'section-info',
        class: 'section-info',
      },
      blocks: {
        default: [
          {
            component: 'Text',

            data: {
              content: transformContent(get('content')),
            },
          },
        ],
      },
    },

    {
      condition: get('customFields.additional-sections-v2.length'),
      component: 'Section',
      props: {
        width: 'full',
        id: 'section-image-text',
        class: 'section-image-text',
        style: 'padding-top:0;',
      },
      blocks: {
        default: [
          {
            props: {
              startDirection: 'odd',
            },
            component: 'ImageText',
            data: {
              content: get('customFields.additional-sections-v2'),
            },
          },
        ],
      },
    },

    {
      component: 'Section',
      condition:
        get('customFields.custom-map.image.url', 'Root.item') &&
        get('customFields.page-settings.display-map'),
      props: {
        width: 'full',
        id: 'section-custom-map',
        class: 'section-custom-map',
      },
      blocks: {
        default: [
          {
            component: 'ScrollAnchor',
            data: 'map',
          },
          {
            component: 'Text',
            props: {
              style:
                'max-width:1200px; margin-bottom:3rem; margin-inline: auto; padding-left: 1rem;',
            },
            data: {
              content: get('customFields.custom-map.description', 'Root.item'),
            },
          },
          {
            component: 'Image',
            data: {
              image: get('customFields.custom-map.image', 'Root.item'),
            },
          },
        ],
      },
    },
    {
      component: 'Section',
      props: {
        width: 'full',
        id: 'section-map',
        class: 'section-map',
        style: 'padding-bottom:0;',
      },
      blocks: {
        default: [
          {
            component: 'ScrollAnchor',
            data: 'map',
          },
          {
            component: 'Map',
            props: {
              aspectRatio: '21/9',
            },
            data: {
              ...get('address', 'Root.item'),
            },
          },
        ],
      },
      condition:
        !get('customFields.custom-map.image.url', 'Root.item') &&
        get('customFields.page-settings.display-map'),
    },

    {
      condtion: get('customFields.attachments.length'),
      component: 'Section',
      props: {
        width: 'medium',
        id: 'section-attachments',
        class: 'section-attachments',
      },
      blocks: {
        default: [
          {
            component: 'ScrollAnchor',
            data: 'attachments',
          },
          {
            component: 'Attachments',
            data: get('customFields.attachments'),
          },
        ],
      },
    },

    {
      component: 'Section',
      props: {
        width: 'medium',
        id: 'section-contact',
        class: 'section-contact',
      },
      blocks: {
        default: [
          {
            component: 'ScrollAnchor',
            data: 'lead',
          },
          {
            component: 'Grid',
            props: {
              gap: '4rem 8rem',
              columns: '2:1',
            },
            blocks: {
              default: [
                {
                  component: 'Lead',
                  props: {
                    options: {
                      placeholders: {
                        'contact.name': this.$t('name'),
                        'contact.email': this.$t('email'),
                        'contact.phone': this.$t('phone'),
                      },
                      fields: ['!title'],
                      actionLabel: this.$t('send'),
                    },
                  },
                  data: {
                    project: get('id', 'Root.item'),
                    reference: { onModel: 'Project', ref: get('id', 'Root.item') },
                  },
                  blocks: {
                    top: [
                      {
                        component: 'Text',
                        data: {
                          content: get('customFields.lead-top-content', 'Root.item'),
                        },
                      },
                    ],
                  },
                },
                {
                  component: 'Roles',
                  props: {},
                  data: {
                    content: get('roles.salesman', 'Root.item'),
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ].filter(f => !('condition' in f) || f.condition)
}
